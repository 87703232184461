import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';


export default () => {
    const { t } = useTranslation();


    const data = useStaticQuery(graphql`
        query WayOfWork {
            step1: file(relativePath: {eq: "step1.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step2: file(relativePath: {eq: "step2.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step3: file(relativePath: {eq: "step3.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step4: file(relativePath: {eq: "step4.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step5: file(relativePath: {eq: "step5.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }
    `);

    const getContent = (translation) => {
        return { __html: `${t(translation)}` }
    }

    return (
        <>
            <section id="wayofwork">
                <div className="container my-5">
                    <div>
                        <div className="text-center">
                            <h2>{t('site.pages.home.wayOfWork.title')}</h2>
                            <hr className="small my-4" />
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mb-5">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-custom-2 px-2 my-4 text-center">
                        <img src={data.step1.childImageSharp.fixed.src} alt="Step 1" className="img-fluid mb-3 way-of-work-image" />
                        <h5 className="way-of-work">{t('site.pages.home.wayOfWork.stepText')} 1</h5>
                        <h6 className="way-of-work" dangerouslySetInnerHTML={getContent('site.pages.home.wayOfWork.step1')}></h6>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-custom-2 px-2 my-4 text-center">
                        <img src={data.step2.childImageSharp.fixed.src} alt="Step 2" className="img-fluid mb-3 way-of-work-image" />
                        <h5 className="way-of-work">{t('site.pages.home.wayOfWork.stepText')} 2</h5>
                        <h6 className="way-of-work">{t('site.pages.home.wayOfWork.step2')}</h6>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-custom-2 px-2 my-4 text-center">
                        <img src={data.step3.childImageSharp.fixed.src} alt="Step 3" className="img-fluid mb-3 way-of-work-image" />
                        <h5 className="way-of-work">{t('site.pages.home.wayOfWork.stepText')} 3</h5>
                        <h6 className="way-of-work">{t('site.pages.home.wayOfWork.step3')}</h6>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-custom-2 px-2 my-4 text-center">
                        <img src={data.step4.childImageSharp.fixed.src} alt="Step 4" className="img-fluid mb-3 way-of-work-image" />
                        <h5 className="way-of-work">{t('site.pages.home.wayOfWork.stepText')} 4</h5>
                        <h6 className="way-of-work">{t('site.pages.home.wayOfWork.step4')}</h6>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-custom-2 px-2 my-4 text-center">
                        <img src={data.step5.childImageSharp.fixed.src} alt="Step 5" className="img-fluid mb-3 way-of-work-image" />
                        <h5 className="way-of-work">{t('site.pages.home.wayOfWork.stepText')} 5</h5>
                        <h6 className="way-of-work">{t('site.pages.home.wayOfWork.step5')}</h6>
                    </div>

                </div>
            </section>

            {/* </div> */}
        </>
    )
}