import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useStaticQuery, graphql } from 'gatsby';


export default () => {
    const { t } = useTranslation();
    const data = useStaticQuery(graphql`
        query OurServices {
            leftImage: file(relativePath: {eq: "scissors-icon.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            rightImage: file(relativePath: {eq: "knife-icon.png"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }
    `);


    const getContent = (translation) => {
        return { __html: `${t(translation)}` }
    }

    return <>
        <div className="bg-gray">
            <div className="container py-5">
                <div className="text-center">
                    <h2>{t('site.pages.home.ourServices.title')}</h2>
                    <hr className="small white my-4" />
                </div>

                <div className="row mt-5">
                    <div className="col-lg-6 text-center px-4 mb-5">
                        <img className="mb-4 ourservices-image" src={data.leftImage.childImageSharp.fixed.src} alt="Scissors" />
                        <h5 className="text-orange mb-4">{t('site.pages.home.ourServices.left.title')}</h5>
                        <div className="text-left" dangerouslySetInnerHTML={getContent('site.pages.home.ourServices.left.content')}></div>

                        {/* <Link to="/scissors" className="btn btn-primary mt-4">{t('site.pages.home.ourServices.left.buttonText')}</Link> */}
                    </div>
                    <div className="col-lg-6 text-center px-4">
                        <img className="mb-4 ourservices-image" src={data.rightImage.childImageSharp.fixed.src} alt="Knives" />
                        <h5 className="text-orange mb-4">{t('site.pages.home.ourServices.right.title')}</h5>
                        <div className="text-left" dangerouslySetInnerHTML={getContent('site.pages.home.ourServices.right.content')}></div>
                        {/* <Link to="/knives" className="btn btn-primary orange mt-4">{t('site.pages.home.ourServices.right.buttonText')}</Link> */}
                    </div>

                </div>
            </div>
        </div>
    </>
}