import { graphql } from "gatsby";
import React from "react";
import Hero from "../components/Hero";
import HowWeSharpen from "../components/home/HowWeSharpen";
import ListOfBusinesses from "../components/home/ListOfBusinesses";
import OurServices from "../components/home/OurServices";
import Testimonials from "../components/home/Testimonials";
import WayOfWork from "../components/home/WayOfWork";
import WeFixBroken from "../components/home/WeFixBroken";
import Layout from "../components/Layout";
import withTrans from "../i18n/withTranslation";

function Home({ data, t }) {

  return (
    <>
      <Layout
        isDark={true}
        title={t('site.pages.home.meta.title')}
        description={t('site.pages.home.meta.description')}
      >
        <Hero
          isBig={true}
          title={t('site.pages.home.title')}
          subtitle={t('site.pages.home.subtitle')}
          alt={t('site.pages.home.headerAlt')}
          isVideo={true}
          img={data.header.childImageSharp.sizes.src}
        >
          <a href={t('site.phone')} className="btn btn-primary orange mt-5">
            <span>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-telephone" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
            </span>
            <span className="ml-2">{t('site.pages.home.buttonText')}</span>
          </a>

        </Hero>

        <WayOfWork />
        <OurServices />
        <ListOfBusinesses />
        <HowWeSharpen />
        <WeFixBroken />
        <Testimonials />
      </Layout>
    </>
  )
}

export default withTrans(Home);

export const query = graphql`
  query Home {
    header: file(relativePath: {eq: "index-hero.jpg"}) {
      childImageSharp {
        sizes(quality: 100) {
          src
        }
      }
    }
  }

`;
