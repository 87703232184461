import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';


const BgWrapper = styled.div`
    position: relative;
    width: 100vw;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0  ;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(${props => props.bgImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        filter: brightness(90%);

        @media screen and (max-width: 991px) {
            background: url(${props => props.bgImageMobile});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }
`

export default () => {
    const { t } = useTranslation();

    const data = useStaticQuery(graphql`
        query WeFixBroken {
            bgImage: file(relativePath: {eq: "home-broken-knives.jpg"}) {
                childImageSharp {
                    sizes(quality: 100) {
                      src
                    }
                }
            }
            bgImageMobile: file(relativePath: {eq: "home-broken-knives-mobile.jpg"}) {
                childImageSharp {
                    sizes(quality: 100) {
                      src
                    }
                }
            }
        }
    `);

    return <>
        <BgWrapper bgImage={data.bgImage.childImageSharp.sizes.src} bgImageMobile={data.bgImageMobile.childImageSharp.sizes.src}>
            <div className="container py-5">
                <div className="text-center text-white">
                    <h2 className="mb-5">{t('site.pages.home.weFixBroken.title')}</h2>
                    {/* <p className="my-4">{t('site.pages.home.weFixBroken.text')}</p> */}
                    <Link to="/faq#6" className="btn btn-primary orange">{t('site.pages.home.weFixBroken.buttonText')}</Link>
                </div>
            </div>
        </BgWrapper>
    </>
}