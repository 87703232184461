import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';


export default () => {
    const { t } = useTranslation();

    return <>
        <div className="bg-orange">
            <div className="container py-5">
                <div className="text-center">
                    <h2>{t('site.pages.home.howWeSharpen.title')}</h2>
                    <hr className="small white my-4" />
                </div>

                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8 text-center">
                        <p className="font-size-26">{t('site.pages.home.howWeSharpen.text')}</p>
                        <Link to="/bevel" className="btn btn-primary dark mt-4">{t('site.pages.home.howWeSharpen.buttonText')}</Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}