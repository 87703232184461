import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';


const Slide = styled.div`
    color: white !important;
    height: 350px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    background: $orange;
    transition: 0.3s background-color ease-in-out;
    overflow: hidden;

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0 20px;
        width: 100%;
        height: 100%;
        background-color: rgba(1,1,1,0.5);

        &:hover {
            background-color: rgba(1,1,1,0.1);
            transition: 0.3s background-color ease-in-out;   
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        background-color: rgba(1,1,1,0.2);
        transition: 0.3s background-color ease-in-out;   
    }

    p {        
        font-size: 26px !important;
        font-style: normal; 
        font-weight: 600;
        text-transform: uppercase;
    }
`;

export default () => {
    const { t } = useTranslation();
    const data = useStaticQuery(graphql`
        query ListOfBusinesses {
            step1: file(relativePath: {eq: "businesses/restaurants.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step2: file(relativePath: {eq: "businesses/hospitals.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step3: file(relativePath: {eq: "businesses/hairdressers.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step4: file(relativePath: {eq: "businesses/sewers.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step5: file(relativePath: {eq: "businesses/clients.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
            step6: file(relativePath: {eq: "businesses/gardens.jpg"}) {
                childImageSharp {
                    fixed {
                        originalName
                        src
                    }
                }
            }
        }
    `);

    const imageList = [
        data.step1.childImageSharp.fixed.src,
        data.step2.childImageSharp.fixed.src,
        data.step3.childImageSharp.fixed.src,
        data.step4.childImageSharp.fixed.src,
        data.step6.childImageSharp.fixed.src,
        data.step5.childImageSharp.fixed.src
    ];

    return <>
        <div className="">
            <div className="container py-5">
                <div className="text-center">
                    <h2>{t('site.pages.home.listOfBusinesses.title')}</h2>
                    <hr className="small my-4" />
                </div>
            </div>

            <div className="row d-flex justify-content-center px-0 mx-0">
                {t('site.pages.home.listOfBusinesses.list').map((el, index) => {
                    return (
                        <Slide
                            className='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 px-0 mx-0'
                            key={index}
                        >
                            <img src={imageList[index]} alt={el} />
                            <div className="content">
                                <p className="mb-0">{el}</p>
                            </div>
                        </Slide>
                    );
                })}
            </div>
        </div>
    </>
}