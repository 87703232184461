import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';


const BgWrapper = styled.div`
    position: relative;
    width: 100vw;
    background-color: rgba(17,17,17,.3);


    &:before {
        content: "";
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(${props => props.bgImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        
        @media screen and (min-width: 991px) {
            background-attachment: fixed;
        }
    }
`


const Slide = styled.div`
    color: white !important;
    min-height: 200px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:active, &:focus {
        outline: none;
        border: none;
    }

    .wrapper {
        max-width: 700px;
        &:active, &:focus {
            outline: none;
            border: none;
        }
    }
    
`;


const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
}

export default () => {
    const { t } = useTranslation();

    const data = useStaticQuery(graphql`
        query Testimonials {
            bgImage: file(relativePath: {eq: "testimonials.jpg"}) {
                childImageSharp {
                    sizes(quality: 60) {
                      src
                    }
                }
            }
        }
    `);

    return <>
        <BgWrapper bgImage={data.bgImage.childImageSharp.sizes.src}>
            <div className="container py-5">
                <div className="text-center text-white">
                    <h2>{t('site.pages.home.testimonials.title')}</h2>
                    <hr className="small white my-4" />
                </div>

                <div className="col-12 align-items-center">
                    <div className="d-block">
                        <Slider
                            {...settings}
                        >
                            {t('site.pages.home.testimonials.list').map((el, index) => {
                                return (
                                    <div className="no-focus">
                                        <Slide key={index}>
                                            <div className="wrapper my-5">
                                                <p className="mb-5">{el.review}</p>
                                                <h6>{el.name}</h6>
                                                <i>{el.business}</i>
                                                {el.image && <img src={el.image} alt={el.business} />}
                                            </div>
                                        </Slide>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        </BgWrapper>
    </>
}